<template>
  <el-pagination
    background
    layout="total, prev, pager, next"
    :page-size="pageSize"
    :total="total"
  />
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    total: {
      type: Number,
      require: true
    },
    pageSize: {
      type: Number,
    },
  }
}
</script>