<template>
  <el-dialog
    v-model="isVisible"
    :title="title"
    @before-close="emit('close')"
  >
    <template #header>
      <slot name="header"></slot>
    </template>
    <slot></slot>
    <template #footer>
      <slot name="footer"></slot>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: 'Modal',
  props:['visible', 'title' ],
  computed: {
    isVisible() {
      return this.visible
    }
  },
  methods: {
    close(ev) {
      console.log(ev)
    }
  }
}
</script>

<style>

</style>