<template>
  <el-skeleton class="container__skeleton" animated>
    <template #template>
      <div class="mb-4" v-for="n in 20" :key="n">
        <div style=" display: flex; align-items: center; justify-items: space-between;"
        >
          <el-skeleton-item variant="h1" style="margin-right: 16px; width: 20%" />
          <el-skeleton-item variant="h1" style="margin-right: 16px; width: 20%" />
          <el-skeleton-item variant="h1" style="margin-right: 16px; width: 35%" />
          <el-skeleton-item variant="h1" style="margin-right: 16px; width: 30%" />
          <el-skeleton-item variant="h1" style="margin-right: 16px; width: 5%" />
          <el-skeleton-item variant="h1" style="width: 30%" />
        </div>
      </div>
    </template>
  </el-skeleton>
</template>

<script>
export default {
  name: 'SkeletonTable',


}
</script>

<style scoped>
  .container__skeleton {
    width: 100%;
    min-height: 100vh;
    background: #fff;
    padding: 1rem;
  }
</style>